import axios from "axios";

// export const BASE_URL ='https://getehr.synapstics.com/api/caremeapp'
export const BASE_URL ='https://getehr.curegrid.co/api/caremeapp'

// export const APP_BASE_URL="synapstics.com"; 
export const APP_BASE_URL="curegrid.co"; 

export const headers= {
    "Content-Type": "application/json",
    "Authorization": "a7143772a09f993125901ea752bfd4fcf4b31d29"
   }

export const PostApi = async(data)=>{
    console.log(data)
    let url = data.url
    let params=data.values
    var val = ''
    await axios.post(BASE_URL+url,params,{headers}).then((res)=>{
        console.log(res.data)
       if(res){
        val= res.data
       }
       else{val= "error"}
    })
    .catch((err)=>{
        console.log(err)
    })
    return val
}

export const getProdKey={};

export const GetApi=async(data)=>{
    console.log(data)
    let url = data.url
    var val = ''
   await axios.get(BASE_URL+url,{headers}).then((res)=>{
      console.log(res.data)
       if(res){
        val= res.data
       }
       else{val= "error"}
    })
    .catch((err)=>{
        console.log(err)
    })
    return val
}



const languageCode = {
    'english': 'en',
    'tamil': 'ta'
  }
  
  export const getLangCode = (lang) => {
    return languageCode[lang];
  }
