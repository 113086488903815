import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loadersvg from '../assets/images/loader.svg';
import { useHistory, useLocation } from "react-router-dom";
import {
  Circle,
  DashCircle,
  PlusCircle,
  Square,
  Subtract,
  TrashFill,
} from "react-bootstrap-icons";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import './Answer.css'
import { APP_BASE_URL } from "../utils/Api";
// const data = [
//   {
//     answer: null,
//     answer_type: "Text Input",
//     key: "TextInput",
//     placeholder: "Enter Your Text",
//     user_answer: null,
//     question: "Question",
//   },
//   {
//     answer: null,
//     answer_type: "Email",
//     key: "EmailID",
//     user_answer: null,
//     placeholder: "Enter Your Email",
//     question: "Question",
//   },
//   {
//     answer: ["Dropdown Value 1", "Dropdown value 2", "Dropdown value 3"],
//     answer_type: "Dropdown",
//     key: "Dropdown",
//     placeholder: "Enter Your Text",
//     question: "Question",
//     user_answer: null,
//   },
//   {
//     answer: [
//       { item: "Checkbox Value 1", check: false },
//       { item: "Checkbox value 2", check: false },
//       { item: "Checkbox value 3", check: false },
//     ],
//     answer_type: "Checkboxes",
//     key: "Checkboxes",
//     placeholder: "Enter Your Text",
//     question: "Question",
//     user_answer: null,
//   },
//   {
//     answer: ["Radio Value 1", "Radio value 2", "Radio value 3"],
//     answer_type: "Multiple Choice",
//     key: "RadioButtons",
//     placeholder: "Enter Your Text",
//     question: "Question",
//     user_answer: null,
//   },
//   {
//     answer: null,
//     answer_type: "Date",
//     key: "DatePicker",
//     placeholder: "Enter Your Text",
//     question: "Question",
//     user_answer: null,
//   },
//   {
//     answer: null,
//     answer_type: "Multi-line Input",
//     key: "TextArea",
//     placeholder: "Enter Your Text",
//     question: "Question",
//     user_answer: null,
//   },
//   {
//     answer: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
//     answer_type: "Scale Input",
//     key: "Range",
//     min_label: "Min",
//     max_label: "Max",
//     placeholder: "Enter Your Text",
//     question: "Question",
//     user_answer: null,
//   },
// ];
const Loader = () => (
  <div className="splash">
    <div className="color-line"></div>
    <div className="splash-title">
      <h1>Loading Form....</h1>
      <img src={Loadersvg} width="128" height="128" />
    </div>
  </div>
);

const Success = () => (
  <div className="box"> 
  <div className="success alerts">
    <div className="alerts-body">
      Success !
    </div>
  </div>
</div>
)

const Answer = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [loader, setLoader]=useState(true);
  const [success, setSuccess]=useState(false);
  const [value, setValue] = useState([]);
  const [date, setDate] = useState(new Date());
  const [valEmail, setValEmail] = useState(false);
  const [formDetails, setFormDetails] = useState({});
  const [patientDetails, setPatientDetails] = useState({});
  const headers = {
    "Content-Type": "application/json",
    Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
  };
  const fetchData = async () => {
    const patientData = { appointment_n_key: location.state?.data?.id};
    const res = await axios.post(
      `https://getehr.${APP_BASE_URL}/api/appoint_patient/`,
      patientData,
      { headers }
    );
    setPatientDetails(res.data.data);
    console.log(res);
  };
  const fetchFormData = async () => {
    const res = await axios.get(
      `https://getehr.${APP_BASE_URL}/api/orderform_details/${location.state?.data?.formId}/`, {},
      { headers }
    );
    console.log(res);
    setFormDetails(res?.data);
    setValue(res.data?.form_data)
  };

  useEffect(() => {
    fetchData();
    fetchFormData();
    location.state?.data?.id && formDetails && patientDetails ? setLoader(false) : history.push('/error');
  }, []);

  const sendForm = () => {
    const data = {
      link_id: 3,
      patient_n_key: patientDetails.patient_n_key,
      appointment_n_key: location.state?.data?.id,
      form: formDetails.title,
      order_form_id: formDetails.order_form_id,
      values: value,
      created_by_id:formDetails.created_by_id,
      created_by_name:formDetails.created_by_name,
    };
    axios
      .post(
        `https://getehr.${APP_BASE_URL}/api/caremeapp/therpayform_post/`,
        data,
        { headers }
      )
      .then((res) => {
        console.log(res);
        if (res.data.status ==='success'){
          setSuccess(true);
          setLoader(false);
        }
      });
  };
  const handleChange = (id, val) => {
    setValue(
      value.map((item, index) => {
        if (id !== index) return item;
        return { ...item, user_answer: val };
      })
    );
  };
  const emailChange = (id, val) => {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regEmail.test(val)) {
      setValEmail(false);
      setValue(
        value.map((item, index) => {
          if (id !== index) return item;
          return { ...item, user_answer: val };
        })
      );
    } else {
      setValEmail(true);
    }
  };
  const handleDrop = (indx, vals) => {
    setValue(
      value.map((item, index) => {
        if (indx !== index) return item;
        return { ...item, user_answer: vals };
      })
    );
  };
  const handleCheck = (indx, id, val, els) => {
    setValue(
      value.map((item, index) => {
        var arr = [item.user_answer];
        if (val) {
          if (arr[0] == null) {
            let newArr = [];
            newArr.push(els);
            arr = newArr;
          } else arr.push(els);
        }
        if (!val) {
          var newA = [].concat.apply([], arr).filter((i) => i !== els);
          arr = newA;
        }
        if (id !== index) return item;
        return { ...item, user_answer: [].concat.apply([], arr) };
      })
    );
  };
  return (
    loader ? <Loader /> : !loader && success ? <Success /> :
    <div className="answer_section">
      <h4 className="form_title">{formDetails.title}</h4>
      {value &&
        value.map((item, index) => {
          return (
            <div className="answer_types" key={index}>
              <p>
                {`${index + 1})`} {item.question}
              </p>
              {item.key === "TextInput" && (
                <input
                  className="input_inline_prev_ans"
                  onChange={(e) => handleChange(index, e.target.value)}
                  placeholder={item.placeholder}
                />
              )}
               {item.key === "NumberInput" && (
                <input
                  type='number'
                  className="input_inline_prev_ans"
                  onChange={(e) => handleChange(index, e.target.value)}
                  placeholder={item.placeholder}
                />
              )}
              {item.key === "EmailID" && (
                <input
                  className="input_inline_prev_ans"
                  onChange={(e) => emailChange(index, e.target.value)}
                  placeholder={item.placeholder}
                />
              )}
              {item.key === "EmailID" && valEmail && (
                <span className="err_msg">Enter a valid email</span>
              )}
              {item.key === "TextArea" && (
                <textarea
                  className="input_inline_txt_prev_ans"
                  onChange={(e) => handleChange(index, e.target.value)}
                  placeholder={item.placeholder}
                ></textarea>
              )}
               {item.key === "Showcase" && (
               <div
               className="show_case"
               >{item.placeholder}</div>
              )}
              {item.key === "DatePicker" && (
                <div className="date_inline_prev_ans">
                  <ReactDatePicker
                    className="date_pic_ans"
                    onChange={(dat) => {
                      setDate(dat);
                      handleChange(index, dat);
                    }}
                    selected={date}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                  />
                </div>
              )}
              {item.key === "RadioButtons" && (
                <div className="inline_radio_ans">
                  <FormGroup>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      //  value={value}
                      onChange={(e) => handleDrop(index, e.target.value)}
                    >
                      {item.answer.map((el, indx) => {
                        return (
                          <div className="check_box_ans">
                            <FormControlLabel
                              key={indx}
                              value={el}
                              control={<Radio />}
                              label={el}
                            ></FormControlLabel>
                          </div>
                        );
                      })}
                    </RadioGroup>
                  </FormGroup>
                </div>
              )}
              {item.key === "Checkboxes" && (
                <div className="inline_radio_ans">
                  <FormGroup>
                    {item.answer.map((el, indx) => {
                      return (
                        <div className="check_box_ans">
                          <FormControlLabel
                            key={indx}
                            control={
                              <Checkbox
                                inputProps={{ "aria-label": "controlled" }}
                                onChange={(e) => {
                                  handleCheck(
                                    indx,
                                    index,
                                    e.target.checked,
                                    el.item
                                  );
                                }}
                              />
                            }
                            label={el.item}
                          ></FormControlLabel>
                        </div>
                      );
                    })}
                  </FormGroup>
                </div>
              )}
              {item.key === "Dropdown" && (
                <select
                  onChange={(e) => handleChange(index, e.target.value)}
                  className="input_inline_prev_drop"
                >
                  {item.answer.map((el) => (
                    <option value={el.value}>{el.value}</option>
                  ))}
                </select>
              )}
              {item.key === "Range" && (
                <div className="form_range_input_ans">
                  <div className="btn_grp_ans">
                    {item.min_label}{" "}
                    {item.answer.map((el, indx) => (
                      <>
                        <button
                          style={{ color: "#fff", fontWeight: "bold" }}
                          onClick={() => handleChange(index, el)}
                          className={`btn_form_range${indx} ${
                            item.user_answer === el ? "active_btn" : null
                          }`}
                        >
                          {el}
                        </button>
                      </>
                    ))}{" "}
                    {item.max_label}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      <button className="submit_btn_ans" onClick={() => sendForm()}>
        Submit
      </button>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Answer);
